.breadcrumb {
  background-color: transparent;

  .breadcrumb-item {
    line-height: 1;

    i {
      margin-right: 5px;
    }
  }
}