body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast--success {
  background: #00AA4E !important; }
.Toastify__toast--warning {
  background: #f1c40f; }
.Toastify__toast--error {
  background: #d62828 !important; }
table thead th{
  white-space: nowrap;
}



/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}
 
 
/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background-color: #00AA4E !important;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    font-size: 16px !important;
    color: #fff;
    padding: 2px !important;
    border: 0 !important;
    line-height: 34px !important;
    padding-right: 10px !important;
    margin-top: 10px;
}
.google-icon-wrapper {
  margin-top: 1px;
  margin-left: 3px;
  border-radius: 2px;
  float: left;
  background-color: #fff;
  padding: 6px;
  margin-right: 10px;
  color: #00AA4E;
}

.text-link{
  color:#007bff!important
}  

.invoSchedule-table thead, .invoSchedule-table tbody tr{
  display: table;
    width: 100%;
    table-layout: fixed;
}

.invoSchedule-table tbody{
  display: block;
    height: 135px;
    overflow: auto;
    width: 100%;
}

.installed-app-title {
  color: #000000 !important;
}

.table-container {
  border: 1px solid #B3B9C4;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
}
  
.table-container .invoSchedule-table thead {
    height: 35px !important;
}

.table-container .table thead th:first-child {
    border-radius: 4px 0 0 0 !important
}

.table-container .table thead th:last-child {
    border-radius: 0 4px 0 0 !important
}

.table-container .table thead th {
    background-color: #DFE2E6 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #e3e3e3 !important;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #EFEFEF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #393939;
}

.table-container .invoSchedule-table tbody {
    display: block;
    height: 138px !important;
    overflow: auto;
    width: 100%;
}

.table-container .invoSchedule-table tbody tr {
    height: 46px !important;
}

.table-container .table tbody td {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 120%;
    color: #393939 !important;
}

.table-container a.btn.btn-sm.far.fa-edit,
.table-container button.btn.btn-sm.far.fa-edit {
    font-size: 16px !important;
    color: #2C71D1 !important;
    background: transparent !important;
}

.table-container a.btn.btn-sm.far.fa-trash-alt,
.table-container button.btn.btn-sm.far.fa-trash-alt {
    font-size: 16px !important;
    color: #dc3545 !important;
    background: transparent !important;
}

